import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React from "react";
import { Bg, CharsBack, CharsMid, Coins, EnterSite, Equipments, Glare, Logo } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Dee99</VisuallyHidden>
      <img
        alt="bg"
        src={Bg}
        style={{
          position: "absolute",
          top: 70,
          width: 423,
          animation: `bgmove 10s linear infinite`,
        }}
      />

      <Box sx={{ position: "relative", width: 360, textAlign: "center" }}>
        <img
          alt="logo"
          src={Logo}
          style={{ width: 156 }}
        />
      </Box>

      <img
        alt="enter site"
        src={EnterSite}
        style={{ zIndex: 1 }}
      />

      <Box sx={styles.images}>
        <img
          alt="chars back"
          src={CharsBack}
          style={{
            animation: "charsback 1.28s linear 0.8s infinite",
            width: 305,
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 40,
          }}
        />
        <img
          alt="chars mid"
          src={CharsMid}
          style={{
            animation: "charsmid 1.28s linear 0.8s infinite",
            width: 240,
            left: 50,
            top: 10,
          }}
        />
        <img
          alt="equipments"
          src={Equipments}
          style={{
            animation: "equipments 1.28s linear 0.8s infinite",
            width: 284,
            top: 230,
            left: 31,
          }}
        />
        <img
          alt="glare"
          src={Glare}
          style={{
            animation: "glare 1.28s linear 0.8s infinite",
            width: 151,
            top: 262,
            left: 78,
          }}
        />
        <img
          alt="coins"
          src={Coins}
          style={{
            animation: "coins 1.28s linear 0.8s infinite",
            width: 355,
            top: -14,
            left: -4,
            right: 0,
            margin: "0 auto",
          }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "46px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "440px",
    img: {
      position: "absolute",
    },
    animation: "fadeBottom 0.8s ease-out",
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
    background: "radial-gradient(800px 100% at 50% 100%, #FFF9C1 0%, #21966C 19%, rgba(0, 0, 0, 0.00) 50%)",
  },
});

export default LandingPage;
