import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const ENTER_SITE_URL = "https://a.vtrk8.com/5e4aa6fe-6c65-4067-bc4e-ff3ed0d2ed56";

export const FLOATING_BANNER_URL = "https://d99th.com/cs";

export * from "./codes";
export * from "./context";
